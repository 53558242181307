import { createAsyncThunk } from '@reduxjs/toolkit'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import type { CouponStatusChange } from '@centrito/api/nest/shopper/cart/domain/types/status-change'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { getCartOwner } from '@centrito/app/store/slices/userData/utils/getCartOwner'

const revalidateCoupon = createAsyncThunk<
  {
    newCart: CartExtendedPublicComposite
    couponStatusChange: CouponStatusChange
  },
  {
    campaignId: string
  },
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/revalidateCoupon`, async (payload, thunkAPI) => {
  const currentState = thunkAPI.getState()
  const cartOwner = getCartOwner(currentState, thunkAPI.dispatch)
  const { couponStatusChange } = await trpcProxyClient.user.cart.refreshDiscountData.mutate(
    cartOwner,
  )
  const newCart = await trpcProxyClient.user.cart.get.query(cartOwner)

  return { newCart, couponStatusChange }
})
export default revalidateCoupon
