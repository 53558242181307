import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import { $Enums } from '@centrito/db'
import { SHIPPING_CITIES } from '@centrito/api/config'
import type { AlliedStorePublic } from '@centrito/api/nest/platform/database/domain'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import type { UpdateCartDeliveryDataInput } from '@centrito/api/nest/shopper/cart/domain/schemas'
import type { CouponStatusChange } from '@centrito/api/nest/shopper/cart/domain/types/status-change'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { getCartOwner } from '@centrito/app/store/slices/userData/utils/getCartOwner'
import metaClient from '@centrito/app/utils/services/analytics/meta'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'

interface UpdateUserAddressPayload {
  input: UpdateCartDeliveryDataInput
}

export interface UpdateUserAddressResponse {
  type: $Enums.OrderDeliveryDestinationType
  newCart: CartExtendedPublicComposite
  couponStatusChange: CouponStatusChange
  alliedStore?: AlliedStorePublic | null
}

export interface UpdateResponse {
  type: $Enums.OrderDeliveryDestinationType
  couponStatusChange: CouponStatusChange
  alliedStore?: AlliedStorePublic | null
}

const updateLocalAlliedStore = async ({
  payload,
}: {
  payload: UpdateUserAddressPayload
}): Promise<UpdateResponse> => {
  const { input } = payload
  if (input.type !== 'LOCAL_ALLIED_STORE') {
    throw new Error('Invalid type')
  }
  const [newCartResult, alliedStoreResult] = await Promise.all([
    trpcProxyClient.user.cart.updateDelivery.mutate({
      type: input.type,
      alliedStoreId: input.alliedStoreId,
    }),
    trpcProxyClient.user.profile.updateLastAlliedStore.mutate({
      alliedStoreId: input.alliedStoreId,
    }),
  ])

  return {
    type: input.type,
    alliedStore: alliedStoreResult.store,
    couponStatusChange: newCartResult.couponStatusChange,
  }
}

const updateLocalDelivery = async ({
  payload,
}: {
  payload: UpdateUserAddressPayload
}): Promise<UpdateResponse> => {
  const { input } = payload
  if (input.type !== 'LOCAL_HOME' && input.type !== 'NATIONAL') {
    throw new Error('Invalid type')
  }
  const isNational = !SHIPPING_CITIES.includes(input.address.city)

  const { couponStatusChange } = await trpcProxyClient.user.cart.updateDelivery.mutate({
    type: isNational
      ? $Enums.OrderDeliveryDestinationType.NATIONAL
      : $Enums.OrderDeliveryDestinationType.LOCAL_HOME,
    address: input.address,
  })

  return {
    type: input.type,
    couponStatusChange,
  }
}

const captureAnalyticsEvent = (userPhoneNumber: string | undefined): void => {
  if (!isNil(userPhoneNumber)) {
    metaClient.captureCustomEvent('shopper_checkout_domicile_updated', {
      ph: userPhoneNumber,
    })
  } else {
    metaClient.captureCustomEvent('shopper_checkout_domicile_updated')
  }
  posthogClient.captureCustomEvent('shopper_checkout_domicile_updated')
}

const updateUserAddress = createAsyncThunk<
  UpdateUserAddressResponse,
  UpdateUserAddressPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/updateUserAddress`, async (payload, thunkAPI) => {
  try {
    const currentState = thunkAPI.getState()
    const cartOwner = getCartOwner(currentState, thunkAPI.dispatch)

    if (payload.input.type === 'LOCAL_ALLIED_STORE') {
      const updateAlliedStoreResponse = await updateLocalAlliedStore({ payload })
      const newCart = await trpcProxyClient.user.cart.get.query(cartOwner)
      return {
        ...updateAlliedStoreResponse,
        newCart,
      }
    }

    const userPhoneNumber = thunkAPI.getState().auth.authenticatedData?.phone
    captureAnalyticsEvent(userPhoneNumber)

    const updateLocalDeliveryResponse = await updateLocalDelivery({ payload })
    const newCart = await trpcProxyClient.user.cart.get.query(cartOwner)
    return {
      ...updateLocalDeliveryResponse,
      newCart,
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})
export default updateUserAddress
