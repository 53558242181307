import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import getUserCart from './getUserCart'
import loadUserData from './loadUserData'

const initializeApp = createAsyncThunk<void, void, AppThunkApiConfig>(
  `${REDUCER_NAMES.USER_DATA}/initializeApp`,
  async (_, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState()
      // Load user data
      if (!isNil(currentState.auth.authenticatedData)) {
        const userDataResult = await thunkAPI.dispatch(loadUserData()).unwrap()
        // If user data is successfully loaded, try to get the user's cart
        if (userDataResult) {
          await thunkAPI.dispatch(getUserCart()).unwrap()
        }
      }
      return
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)

export default initializeApp
