import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { getCartOwner } from '@centrito/app/store/slices/userData/utils/getCartOwner'

export interface GetUserCartResponse {
  newCart: CartExtendedPublicComposite
}

const getUserCart = createAsyncThunk<GetUserCartResponse, void, AppThunkApiConfig>(
  `${REDUCER_NAMES.USER_DATA}/getUserCart`,
  async (payload, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState()
      const cartOwner = getCartOwner(currentState, thunkAPI.dispatch)
      await trpcProxyClient.user.cart.refreshDiscountData.mutate(cartOwner)
      const newCart = await trpcProxyClient.user.cart.get.query(cartOwner)
      // TODO, there might be a better way to serialize this
      const serializedCart = {
        ...newCart,
        items: newCart.items.map((item) => ({
          ...item,
          product: {
            ...item.product,
            highestCampaign: isNil(item.product.highestCampaign)
              ? null
              : {
                  ...item.product.highestCampaign,
                  createdAt: item.product.highestCampaign.createdAt.toISOString(),
                  expiresAt: isNil(item.product.highestCampaign.expiresAt)
                    ? null
                    : item.product.highestCampaign.expiresAt.toISOString(),
                },
          },
        })),
      } as unknown as CartExtendedPublicComposite
      return { newCart: serializedCart }
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)

export default getUserCart
