import isUndefined from 'lodash.isundefined'
import type { CustomEventProperties } from './common'

export function handleSharedEvents(
  eventName: string,
  customEventProperties?: CustomEventProperties,
): Record<string, any> {
  const eventProperties: Record<string, any> = {}

  switch (eventName) {
    case 'shopper_order_created':
      eventProperties.$set = { hasOrdered: true }
      break

    case 'shopper_new_registration':
      eventProperties.$set = { hasOrdered: false }
      break

    case 'shopper_update_cart_items':
      if (isUndefined(customEventProperties?.quantityChange)) return {}
      eventProperties.quantityChange = customEventProperties!.quantityChange > 0 ? '1' : '-1'
      break

    case 'shopper_delivery_type':
      eventProperties.deliveryType = customEventProperties?.isPickupStore
        ? 'pickup_store'
        : 'home_or_office'
      break

    case 'shopper_product_list_filter_pressed':
      eventProperties.filterType = customEventProperties?.filterType
      break

    case 'shopper_pageview_product':
    case 'shopper_product_page_add_to_cart':
    case 'shopper_product_page_view_image':
    case 'shopper_product_page_full_description':
    case 'shopper_product_page_help':
    case 'shopper_product_page_share_open':
    case 'shopper_product_page_view_brand_products':
    case 'shopper_product_page_view_other_product_with_reviews':
    case 'shopper_product_page_whatsapp_reveal':
    case 'shopper_product_page_whatsapp_open':
      eventProperties.productId = customEventProperties?.productId
      eventProperties.productName = customEventProperties?.productName
      eventProperties.merchantId = customEventProperties?.merchantId
      eventProperties.categoryId = customEventProperties?.categoryId
      break

    case 'shopper_subhome_open':
    case 'shopper_pageview_category':
      eventProperties.categoryName = customEventProperties?.categoryName
      break

    case 'shopper_subhome_brand_see_more_pressed':
    case 'shopper_subhome_category_see_all_pressed':
    case 'shopper_subhome_coupon_banner_pressed':
      eventProperties.subhome = customEventProperties?.subhome
      break

    case 'shopper_subhome_category_open':
      eventProperties.categoryName = customEventProperties?.categoryName
      eventProperties.subhome = customEventProperties?.subhome
      break

    case 'shopper_subhome_brand_open':
      eventProperties.brandName = customEventProperties?.brandName
      eventProperties.subhome = customEventProperties?.subhome
      break

    case 'shopper_checkout_payment_selected':
      eventProperties.paymentMethod = customEventProperties?.paymentMethod
      eventProperties.paymentBank = customEventProperties?.paymentBank
      break

    case 'shopper_pageview_checkout_cart':
    case 'shopper_checkout_allied_store_updated':
    case 'shopper_checkout_domicile_updated':
    case 'shopper_pageview_checkout_payment_nequi':
    case 'shopper_pageview_checkout_payment_nequi_notification':
    case 'shopper_pageview_checkout_payment_nequi_nopayment':
    case 'shopper_pageview_checkout_login':
    case 'shopper_pageview_checkout_register':
    case 'shopper_pageview_auth_login':
    case 'shopper_pageview_auth_register':
    case 'shopper_pageview_checkout_single':
    case 'shopper_pageview_checkout_payment_pse':
    case 'shopper_pageview_auth_login_otp':
      break

    default:
      console.log('==> Posthog: Not a shared event')
      return {}
  }

  return eventProperties
}
