// In order to use uuidv4 we need to import 'react-native-get-random-values' for it to work on mobile
import 'react-native-get-random-values'
import { $Enums } from '@centrito/db'
import { type UserDataState } from './types'

const initialState: UserDataState = {
  userProfile: null,
  cart: {
    cart: {
      id: '',
      anonymousUserId: null,
      deliveryData: null,
      deliveryDate: null,
      deliveryType: null,
      discountData: null,
      paymentData: null,
      ongoingPaymentRequestId: null,
      validPaymentMethods: {
        methods: [
          $Enums.OrderPaymentMethod.CASH,
          $Enums.OrderPaymentMethod.PALOMMA__PSE,
          $Enums.OrderPaymentMethod.PALOMMA__BANCOLOMBIA,
          $Enums.OrderPaymentMethod.PALOMMA__CARD,
        ],
        reason: undefined,
      },
    },
    items: [],
    pricingData: {
      priceItemsDiscount: 0,
      priceItemsCost: 0,
      priceItemsTax: 0,
      priceItemsRevenue: 0,
      priceItemsSubtotal: 0,
      priceCartDiscount: 0,
      priceShippingRevenue: 0,
      priceCartSubtotal: 0,
      priceCartTotal: 0,
    },
  },
  selectedStore: null,
  checkout: {
    isActionEnabled: true,
    deliveryStepState: {
      selectedType: null,
    },
    paymentStepState: {
      selectedMethod: null,
      selectedBank: null,
      selectedBankName: null,
    },
  },
  searchedTerms: [],
  anonymousCartId: null,
  anonymousUserId: null,
  sessionProductIds: [],
  isEmailNotificationsEnabled: false,
}

export default initialState
