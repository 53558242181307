import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useDispatch } from '@centrito/app/store'
import { initializeApp } from '@centrito/app/store/slices/userData/thunks'

export interface AppStateContext {
  isScrollingToTop: boolean
  setIsScrollingToTop: React.Dispatch<React.SetStateAction<boolean>>
  selectedCategoryIndex: number
  setSelectedCategoryIndex: React.Dispatch<React.SetStateAction<number>>
  isInitialized: boolean
}

const initialContext: AppStateContext = {
  isScrollingToTop: false,
  setIsScrollingToTop: () => {},
  selectedCategoryIndex: 0,
  setSelectedCategoryIndex: () => {},
  isInitialized: false,
}

export const AppStateContext = createContext<AppStateContext>(initialContext)

const AppStateProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [isScrollingToTop, setIsScrollingToTop] = useState<boolean>(false)
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(0)
  const [isInitialized, setIsInitialized] = useState<boolean>(false) // New state
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isInitialized) {
      dispatch(initializeApp())
      setIsInitialized(true)
    }
  }, [dispatch, isInitialized])

  const value = useMemo(
    () => ({
      isScrollingToTop,
      setIsScrollingToTop,
      selectedCategoryIndex,
      setSelectedCategoryIndex,
      isInitialized,
    }),
    [isScrollingToTop, selectedCategoryIndex, isInitialized],
  )

  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>
}

export default AppStateProvider
