import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import type { CouponValidationStatus } from '@centrito/api/nest/shopper/cart/domain/types/coupon-validation-status'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { getCartOwner } from '@centrito/app/store/slices/userData/utils/getCartOwner'

const addCoupon = createAsyncThunk<
  {
    newCart: CartExtendedPublicComposite
    couponValidationStatus: CouponValidationStatus
    isUsingCouponAlready: boolean
  },
  ({ couponId: string; couponCode?: never } | { couponCode: string; couponId?: never }) & {
    isReplacingCoupon?: boolean
  },
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/addCoupon`, async (payload, thunkAPI) => {
  const currentState = thunkAPI.getState()
  const cartOwner = getCartOwner(currentState, thunkAPI.dispatch)

  try {
    const couponStatus = await trpcProxyClient.user.cart.addCoupon.mutate({ ...payload, cartOwner })
    const newCart = await trpcProxyClient.user.cart.get.query(cartOwner)

    return {
      ...couponStatus,
      newCart,
    }
  } catch (e) {
    return thunkAPI.rejectWithValue(miniSerializeError(e))
  }
})
export default addCoupon
